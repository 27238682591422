.footer{
    width: 100%;
    max-width: 465px;
    bottom: 32px;
    z-index: 100;
    margin: 0 auto;

    &-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-info{
        padding-bottom: 16px;
        display: flex;
        justify-content: space-evenly;

        &-link{
            font-size: 16px;
            text-align: center;
            text-decoration: none;
            color: white;
        }
    }

    img{
        height:100%
    }

    .fa {
        padding: 20px;
        font-size: 30px;
        width: 30px;
        text-align: center;
        text-decoration: none;
        border-radius: 50%;
        background: transparent;
        color: white;

        &.linktree{
            padding: 0
        }
      }
}