.merch{
    &-content{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        /* margin-bottom: 16px; */
        padding: 5px;
        font-size: 14px;
        font-weight: 400;
        border: 1px solid white;
    }

    &-divider{
        margin: 32px 0;
    }

    &-price{
        font-weight: bolder;
    }

    &-container{
        display: flex;
        flex-direction: column;
        width:100%;
        margin: 0 auto;
        margin-bottom: 24px;
    }

    &-article{
        &-container{
            display: flex;
            flex-wrap: wrap;
            margin: 0 24px;
        }
    }

    &-image {
        position: relative;
        text-align: center;

        img{
            width: 100%;
            height: 500px;
            object-fit: cover;
        }

        .merch-availability{
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            bottom:0;
            right: 0;
            transform:rotate(-45deg);
            font-size: 48px;
            vertical-align: center;
            justify-content: center;
            align-items: center;
            color:red;
            padding:5px;
            font-weight: bold;
        }
    }
}

@media only screen and (min-width: 800px) {

    .merch{
        &-container{
            display: flex;
            flex-direction: column;
            width: 47%;
        }

        &-image{
            img{
                width: 100%;
                height: 410px;
                object-fit: cover;
            }
        }
    }
}

@media only screen and (min-width: 1280px) {

    .merch{
        &-container{
            display: flex;
            flex-direction: column;
            width: 31%;
        }
    }
}