.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 24px;
  position: fixed;
  overflow: hidden;
  width: 100%;
  background-color: black;
  z-index: 100;

  &-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    padding: 0;
    width:100%;
    max-width: 1140px;

    .crop {
      height:60px;
      padding-bottom: 10px;
    }

    img{
      width: 115px;
      margin: -10px;
    }

    a {
      display: block;
      color: white;
      text-align: center;
      text-decoration: none;
    }
  }
}

@media only screen and (min-width: 500px) {
  .header {
      font-size: 32px;

      &-nav{
        .crop{
          height:100px;
        }

        img {
          width: 170px;
        }
      }
  }
}