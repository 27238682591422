body {
    color: white;
    background-color: black;
    font-family: 'Metal Mania';
    margin: 0;
}

hr {
    color: white;
    margin-bottom: 0;
    margin-top: 0;
    width: 100%;
}

.background-logo {
    background-image: url("../images/logo.jpeg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
    background-size: contain;
    background-position: center;
    opacity: 0.1;
    z-index: -2;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.content {
    padding-top: 70px;
    opacity: 1;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0 auto;
    max-width: 1024px;

    &-text {
        margin-bottom: 16px;
    }

    &-headline {
        text-align: center;
        margin-bottom: 12px;
    }
}

p {
    font-size: 16px;
}


h1 {
    margin: 0;
    padding: 16px 0;
}

h3 {
    font-size: 24px;
}

.main-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 8px;
}

/* Wartungs-Seite */

.construction-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 60px;
}

@media only screen and (min-width: 500px) {

    .content {
        padding-top: 120px;
        opacity: 1;
    }
}