.album{
    &-container{
        display:flex;
        flex-direction: column;
    }

    &-link{
        &-container{
            display: flex;
            justify-content: space-between;
        }
    }

    &-text{
        &-content{
            width: 100%;
            text-align: center;
        } 
    }

    &-image{
        width:100%;
    }
}