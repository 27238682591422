.media {
    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 24px;

        iframe {
            width: 400px !important;
            height: 225px !important;
        }
    }
}

@media only screen and (min-width: 800px) {
    .media {
        &-content {
            iframe {
                width: 700px !important;
                height: 450px !important;
            }
        }
    }
}