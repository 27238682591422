.image {
    &-select {
        display: flex;
        position: absolute;
        justify-content: space-around;

        &-button {
            padding: 12px;
            font-size: 16px;
            cursor: pointer;
            border: 1px solid white;
            background-color: black;
            z-index: 2;

            &.selected {
                background-color: #154a2b;
            }
        }

    }
}