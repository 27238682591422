.band {
    &-content {
        display: flex;
        justify-content: center;
    }

    &-members {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        justify-content: center;
    }

    &-member {
        &-element {
            display: flex;
            flex-direction: column;
            margin: 24px;
            border: 1px solid white;
            width: 100%;
            position: relative;
        }

        &-image-container {
            img {
                width: 100%;
            }
        }

        &-info {
            text-align: center;
            position: absolute;
            bottom: 0;
            background-color: black;
            opacity: 0.8;
            width: 100%;
        }
    }

    &-files {
        display: flex;
        justify-content: space-around;
        font-size: 24px;
        text-decoration: none;
        padding: 16px;
        margin-bottom: 16px;

        a {
            color: white;
        }
    }
}

@media only screen and (min-width: 500px) {
    .band{
        &-member{
            &-element{
                width: 40%;
                max-width: 600px;
            }
        }
    }
}

@media only screen and (min-width: 800px) {

    .band {
        &-content{
            margin: 0 auto;
            padding: 8px;
        }
    }
}