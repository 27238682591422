.vinyl-slider{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .slide-image{
        height: 60vw;
    }

    &-slide{
        width: 100%;
        
        padding: 20px;

        .react-slideshow-container .default-nav:last-of-type{
            margin: 20px;
        }

        .react-slideshow-container .default-nav:first-of-type{
            margin: 20px;
        }
    }
}

@media only screen and (min-width: 800px) {
    .vinyl-slider{
    
        &-slide{
            width: 80%;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .vinyl-slider{
        .slide-image {
            height: 600px;
        }
    }
}