.cart{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 800;
    height: 35px;
    width: 70px;
    background-color: aquamarine;

    &-container{
        display: flex;
        height: 100%;
        justify-content: space-between;
    }

    &-counter{
        font-size: 23px;
        line-height: 30px;
    }
}
